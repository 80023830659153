const ROUTES = {
  HOME: "/",
  BROWSE: "/browse",
  SEARCH: "/search",
  PRODUCT: "/product",
  CONFIGURATOR: "/configurator",
  SYSTEM_DESIGNERS: "/systemdesigner",
  FAMILY_SEARCH: "/familysearch",
  CALLBACK: "/callback",
  LOGOUT: "/logout",
  LOGOUT_CALLBACK: "/logout/callback",
};

export default ROUTES;
